.sliderSection {
  position: sticky;
  top: 0;
  width: 70%;
  background-color: var(--text-color);
}
.sliderSection img {
  height: 100%;
  object-fit: contain;
}

.sliderSection div {
  height: 100%;
}
.popUp {
  position: fixed;
  width: 80vw;
  height: 80%;
  background-color: var(--main-color);
  top: 10%;
  left: 10vw;
  z-index: 1;
  box-shadow: 0px 0px 50vw var(--text-color);
  display: flex;
  overflow-y: auto;
  border-radius: 8px;
}
.infoSection {
  width: 30%;
  padding: 20px;
  color: var(--text-color);
}
.infoSection h3 {
  color: var(--active-green);
  font-size: 30px;
  border-bottom: 2px solid;
}
.infoSection p {
  white-space: pre-line;
}

@media screen and (max-width: 775px) {
  .popUp {
    flex-direction: column;
  }
  .sliderSection {
    width: 100%;
    position: relative;
    max-height: 400px;
  }
  .infoSection {
    width: 100%;
    box-sizing: border-box;
  }
  .infoSection h3 {
    margin: 10px 0;
  }
}
