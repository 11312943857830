@import "./assets/styles/index.css";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  font-family: Poppins, sans-serif;
  box-sizing: border-box;
}
.slick-prev {
  left: 25px;
}
.slick-next {
  right: 25px;
}
.slick-prev,
.slick-next {
  z-index: 9;
  width: 50px;
  height: 50px;
  &::before {
    font-size: 45px;
  }
}
