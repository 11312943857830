.P-social-icons img {
  cursor: pointer;
}

.P-footer * {
  color: #343434; /* var(--text-color) */
}

.P-footer .P-contacts-area-container {
  background: #1d8559; /* var(--active-green) */
  width: 100%;
}

.P-footer .P-contacts-area h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #ffffff;
}

.P-footer .P-contacts-area p {
  font-size: 16px;
  width: 60%;
  min-width: 240px;
  color: #ffffff;
}

.P-footer .P-contacts-area .P-btns button {
  font-size: 14px;
  color: #141418;
  background: #ffffff;
  min-width: 100px;
  padding: 0 10px;
  height: 40px;
  border-radius: 4px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.P-footer .P-contacts-area .P-btns {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.P-footer .P-contacts-area {
  width: 100%;
  text-align: center;
  padding: 30px 0;
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #343434; /* var(--text-color) */
}

.P-footer .P-footer-nav .G-container .P-footer-navigation {
  width: fit-content;
  gap: 10px;
  justify-content: space-between;
}

.P-footer .P-footer-nav .G-container .P-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 35%;
}

.P-footer .P-footer-nav .G-container .P-social-icons .P-icons {
  display: flex;
  gap: 15px;
}

.P-footer .P-footer-nav .G-container .P-social-icons {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 11px;
  font-weight: 600;
}

.P-footer .P-footer-nav .G-container .logo {
  height: 47px;
}

.P-footer .P-footer-nav .G-container {
  /* flex-direction: column; */
  align-items: center;
  gap: 30px;
}

.P-footer .P-footer-nav {
  height: auto;
}

.P-footer {
  margin: auto 0 0;
  width: 100%;
}

.P-footer-nav {
  width: 100%;
  background: #f9f9f9; /* var(--main-color) */
  padding: 56px 0;
}

.P-footer-nav a {
  color: #1d8559; /* var(--active-green) */
  line-height: 1.5;
  padding: 5px 0;
}

.P-footer-nav .G-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.P-footer-logo {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.P-footer-logo .P-social-icons {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.P-footer-logo .P-social-icons .P-icons {
  display: flex;
  gap: 21px;
}

.P-footer-logo .logo {
  height: 67px;
}

.P-footer-logo span {
  display: flex;
  align-items: center;
  gap: 20px;
}

.P-footer-logo .logoCreator {
  height: 27px;
}

.P-footer-nav * {
  font-size: 14px;
}

.P-footer-navigation {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.P-footer-navigation .P-sections {
  line-height: 30px;
}

.P-footer-navigation .P-sections a {
  display: block;
  text-transform: uppercase;
}

@media screen and (max-width: 671px) {
  .P-footer .P-footer-nav .G-container {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30px;
  }
  
  .P-sections {
    max-width: 100%;
  }
}
