.homePage {
}

.aboutUs .container img {
  width: 50%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.aboutUs .container .aboutUsText {
  text-align: start;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: #f9f9f9; /* var(--main-color) */
}

.aboutUs .container .aboutUsText h3 {
  font-size: 28px;
  width: 70%;
  margin-block-start: 0;
}

.aboutUs .container .aboutUsText p {
  font-size: 22px;
}

.aboutUs .container .aboutUsText a {
  font-size: 14px;
  color: #1d8559; /* var(--active-green) */
  margin: 20px 0 0 auto;
  background-color: #f9f9f9; /* var(--main-color) */
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid #f9f9f9; /* var(--main-color) */

  &:hover {
    background-color: #1d8559; /* var(--active-green) */
    color: #f9f9f9; /* var(--main-color) */
  }
}
.aboutUs .container {
  display: flex;
  gap: 5%;
  padding: 40px 20px;
  height: fit-content;
}
.aboutUs {
  background-color: #1d8559; /* var(--active-green) */
}
.gallery .container {
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.gallery .container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.gallery .container > * {
  width: calc((100% - 15px) / 4) !important;
  display: block;
  aspect-ratio: 1 !important;
}
.gallery {
  padding: 20px 0;
  -webkit-mask-image: linear-gradient(to bottom, #000 55%, transparent 100%);
  mask-image: linear-gradient(to bottom, #000 55%, transparent 100%);
}

@media screen and (max-width: 991px) {
  .aboutUs .container {
    flex-direction: column;
    gap: 20px;
  }
  .aboutUs .container img {
    width: 100%;
    border-radius: 4px;
  }

  .aboutUs .container h3 {
    margin: 0;
  }
  .gallery .container > * {
    width: calc((100% - 10px) / 3) !important;
  }
}

@media screen and (max-width: 415px) {
  .aboutUs .container .aboutUsText h3 {
    width: 100%;
  }

  .gallery .container > * {
    width: calc((100% - 5px) / 2) !important;
  }
}

@media (hover: hover) {
  .aboutUs .container .aboutUsText a:hover {
    background-color: #1d8559; /* var(--active-green) */
    color: #f9f9f9; /* var(--main-color) */
  }
}
