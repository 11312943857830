.gallery{
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 20px);
    margin: 0 -10px;
    padding-bottom: 20px;
}



