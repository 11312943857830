.languageSwitcher {
    width: fit-content;
    min-width: 100px;
    position: relative;
    color: #343434; /* var(--text-color) */
}

.language_switcher_head {
    border-radius: 8px;
    border: 1px solid #343434; /* var(--text-color) */
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}

.language_switcher_head [class*="icon"] {
    font-size: 30px;
    width: fit-content;
}

.language_switcher_row {
    padding-right: 0;
}

.language_switcher_list {
    list-style: none;
    padding: 0;
    position: absolute;
    width: 100%;
    border-radius: 8px;
    max-height: 0;
    box-sizing: border-box;
    overflow: hidden;
    transition: .3s all ease-in-out;
    border: 1px solid transparent;
}

.language_switcher_list_active {
    max-height: 200px;
    display: block;
    border: 1px solid #343434; /* var(--text-color) */
}

.language_switcher_row {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    background-color: #f9f9f9; /* var(--main-color) */
    font-weight: 600;
}

.flag {
    width: 30px;
    height: 20px;
    border-radius: 4px;
}

.country_name {}

@media (hover: hover) {
    .language_switcher_row:hover {
        background-color: #1d8559; /* var(--active-green) */
        color: #f9f9f9; /* var(--main-color) */
    }

    .language_switcher_head .language_switcher_row:hover {
        background-color: #f9f9f9; /* var(--main-color) */
        color: #343434; /* var(--text-color) */
    }
}
