.contacts {
  padding-bottom: 197px;
   
 
}
.contacts h2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: #23232f;
  margin-top: 86px;
  margin-bottom: 59px;
}

.contacts h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #434353;
  margin-bottom: 8px;
}

.contacts p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #434353;
  margin-bottom: 39px;
}

.contacts span {
  color: #1d8559; /* var(--active-green) */
  text-decoration: underline;
  cursor: pointer;
}

.contacts .divider {
  width: 90%;
  max-width: 360px;
  margin: 0 auto 39px;
  border: 1px solid #1d8559; /* var(--active-green) */
}

.contacts h4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #23232f;
  margin-bottom: 14px;
}

.contacts .icons {
  margin: 0 auto;
  justify-content: center;
  gap: 24px;
}

@media screen and (max-width:991px) {
  .contacts{
    padding-bottom: 60px;
  }
  .contacts * {
    text-align: left !important;
  }
  
  .contacts h2 {
    margin: 30px  0;
  }
  .P-icons {
    justify-content: flex-start;
  }

  .divider {
    width: 100%;
    max-width: none;
  }

  h2 {
    margin: 32px 0;
  }
}
