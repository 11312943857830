.cardBox {
  padding: 10px;
  width: 25%;
}

.cardBox .card {
  padding: 10px 10px 20px;
  border-radius: 8px;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
}

.cardBox .card img {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
}

.cardBox .card p {
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  white-space: pre-line;
  max-height: 5.4em;
  line-height: 1.2em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cardBox .card * {
  color: #343434; /* var(--text-color) */
}

.cardBox .card a {
  color: #1d8559; /* var(--active-green) */
}

@media (hover: hover) {
  .cardBox .card:hover {
    box-shadow: 0px 0px 40px -10px #343434; /* var(--text-color) */
    transform: scale(1.01);
  }
}

@media screen and (max-width: 991px) {
  .cardBox {
    width: 33.3333%;
  }
}

@media screen and (max-width: 775px) {
  .cardBox {
    width: 50%;
  }
}

@media screen and (max-width: 500px) {
  .cardBox {
    width: 100%;
  }
}
