@import "src/assets/styles/index.css";

.header {
  width: 100%;
  background: #f9f9f9; /* var(--main-color) */
}
.logo {}
.headerContainer {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
}

.main_navigation {
  display: flex;
  align-items: center;
  gap: 20px;
}
.menu {
  display: flex;
  width: 100%;
  margin-left: 10%;
  justify-content: space-between;
}
.navigation_item {
  position: relative;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: #343434; /* var(--text-color) */
}
.navigation_item.active:before {
  position: absolute;
  bottom: -10px;
  content: "";
  border-bottom: 2px solid #1d8559; /* var(--active-green) */
  animation: line 0.2s forwards ease-in-out;
}

.menuIcon {
  display: none;
}
.row {
  display: none;
}

@keyframes line {
  0% {
    width: 0;
  }

  40% {
    width: 10%;
  }

  100% {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .menu {
    display: flex;
    width: 100%;
    margin-left: 0%;
    justify-content: center;
    gap: 20%;
    align-items: center;
    flex-direction: column;
    position: fixed;
    height: 100%;
    background-color: #f9f9f9; /* var(--main-color) */
    top: 0;
    left: 100%;
    transition: 0.2s all ease-in-out;
    padding: 10%;
    box-sizing: border-box;
    z-index: -1;
  }
  .menu.active {
    left: 0%;
  }
  .menu .main_navigation {
    flex-direction: column;
  }
  .header.active {
    position: fixed;
    z-index: 10;
    top: 0;
  }
  .menuIcon {
    display: block;
    width: 32px;
    height: 32px;
    position: relative;
  }
  .row {
    display: block;
    width: 100%;
    height: 6px;
    background-color: #1d8559; /* var(--active-green) */
    position: absolute;
    transition: 0.2s all ease-in-out;
    border-radius: 2px;
    z-index: 10;
  }
  .row:nth-child(1) {
    top: 0;
  }
  .active .row:nth-child(1) {
    transform: translateY(-50%) rotate(45deg);
    top: 50%;
  }
  .row:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }
  .active .row:nth-child(2) {
    display: none;
  }
  .row:nth-child(3) {
    top: 100%;
    transform: translateY(-100%);
  }
  .active .row:nth-child(3) {
    transform: translateY(-50%) rotate(-45deg);
    top: 50%;
    }
}
