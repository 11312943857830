.myVideo {
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 16/9;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.mainContainer {
  position: relative;
}
.textConatiner {
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  padding: 30px calc(40% + 30px) 30px 30px;
  color: #f9f9f9; /* var(--main-color) */
  box-sizing: border-box;
  text-align: start;

  * {
    text-shadow: 0 0 #343434; /* var(--text-color) */
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h3,
  h4 {
    text-transform: uppercase;
  }

  p,
  li {
    font-size: 18px;
  }

  li {
    text-align: start;
  }
}

@media screen and (max-width: 775px) {
  .textConatiner {
    padding: 20px;

    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 16px;
    }

    h3,
    h4 {
      text-transform: uppercase;
    }

    p,
    li {
      font-size: 14px;
    }
  }
}
